import * as bootstrap from "bootstrap";
import fslightbox from "fslightbox";
import Swiper from "swiper";
import Macy from "macy";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.min.css";
import "../sass/index.scss";

console.log("Bem-vindo ao F22 Studio!");

const swiper = new Swiper(".swiper", {
  loop: true,

  modules: [Navigation, Pagination, Autoplay],

  autoplay: {
    delay: 5000,
  },

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

if (document.getElementById("macy-container")) {
  const macyInstance = Macy({
    container: "#macy-container",
    trueOrder: false,
    waitForImages: false,
    margin: 16,
    columns: 6,
    breakAt: {
      1200: 6,
      940: 3,
      520: 2,
      300: 1,
    },
  });

  // console.log(macyInstance.constants.EVENT_IMAGE_ERROR)

  macyInstance.on(macyInstance.constants.EVENT_IMAGE_ERROR, console.log)

  macyInstance.on(macyInstance.constants.EVENT_IMAGE_COMPLETE, function (ctx) {
    macyInstance.recalculate(true);
    document.getElementById("loader").style.display = 'none'
    document.getElementById("macy-loader-container").style.transition = 'all 300ms ease-in-out'
    document.getElementById("macy-loader-container").style.height = ''
    document.getElementById("macy-loader-container").style.overflow = 'auto'
    document.getElementById("macy-loader-container").style.opacity = '1'
  });
}

if (document.getElementById('anuncio')) {
  const anuncioModal = new bootstrap.Modal(document.getElementById('anuncio'), {
    backdrop: 'static'
  })
  
  
  if (window.location.pathname !== "/") {
    if (!localStorage.getItem('forgetWarning1') || localStorage.getItem('forgetWarning1') === 'false') {
      anuncioModal.show()
    }
  }
  
  
  document.getElementById('checkAviso').addEventListener('change', function(e) {
    if (e.target.checked) {
      return localStorage.setItem('forgetWarning1', 'true')
    }
    localStorage.setItem('forgetWarning1', 'false')
  })
}
